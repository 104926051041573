.photo-overlay {
  padding-top: 80px;
  height: calc( 100vh - 55px);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative; }
  .photo-overlay:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    pointer-events: none;
    background: rgba(7, 7, 7, 0.5); }

@media all and (max-device-width: 1024px) {
  .photo-overlay {
    background-attachment: scroll; } }

.bg-dark-opaque {
  background: rgba(7, 7, 7, 0.5); }
